import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./news.css";
import axios from "axios";

export default function News(props) {
  const params = useParams();

  // Project Variables
  const [loadingNews, setLoadingNews] = useState(true);
  const [news, setNews] = useState([]);  
  const [loadingImages, setLoadingImages] = useState([]);
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    getNews();
    getMedia();
  }, []);

  async function getNews() {
    if (loadingNews) {
      const returnInfo = await axios.get("https://mr.lerudi.com/wp-json/wp/v2/news?per_page=100");
      await setNews(returnInfo.data);
      setLoadingNews(false);
    }
  }

  async function getMedia() {
    if (loadingImages) {
      const returnInfo = await axios.get(
        "https://mr.lerudi.com/wp-json/wp/v2/media?per_page=100"
      );
      await setImages(returnInfo.data);
      setLoadingImages(false);
    }    
  }

  function switchMainProjectImage(newImage) {
    document.getElementById("mainnewshowImage").src=newImage;
  }

  function showNewsEntry(NewsEntryId) {
    // Loop through the news in the "ProjectParameter" parameter
    let curNewsEntry = "";
    if (NewsEntryId===undefined) {
      curNewsEntry = news[0];
    } else {
      curNewsEntry = news.filter(x=>Number(x.id)===Number(NewsEntryId))[0];
    }
    
    const curNewsEntryPicture = loadingImages ? "" : images.filter(x=>Number(x.id)===Number(curNewsEntry.acf.picture))[0].source_url;
    
    if (curNewsEntry) {
      const a = curNewsEntry.acf.content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2');
                      return <div className="col-12">
                                <div className="row">
                                  <div className="col-sm-12 col-lg-9"><img className="newshow-image" id="mainnewshowImage" alt="" src={curNewsEntryPicture} /></div>
                                  <div className="col-sm-12 col-lg-3">
                                    <h3 className="">{curNewsEntry.title.rendered}</h3>
                                    <span className="newsEntryContent" dangerouslySetInnerHTML={{__html: a}}></span>      
                                  </div>
                                </div>
                              </div>
                      }    
  }

  function limitText (inText) {
    return inText.substr(0,115) + "..."
  }

  function showNews() {
    // Loop through the news
    return news
      .map((Project) => (
        <div key={"project_" + Project.id}>     
          <a className="newsTitle" href={"/news/" + Project.id}>{Project.title.rendered}</a>
          <br/>
          <span className="newsDate">{Project.date}</span>
          <br/>
          <span className="newsContent desktopView">{limitText(Project.acf.content)}</span>
          <br/>
        </div> 
      ));
  }

  return (
    <div> 
        <div className="row">
        <div className="col-sm-12 mobileView">
          <h3>News</h3>
          {loadingNews ? "Loading..." : showNews()}    
          <hr/>     
        </div>
        <div className="col-sm-12 rightBorder col-lg-10">
          {loadingNews ? "Loading contents..." : showNewsEntry(params.newsEntryId)}
        </div>
        <div className="col-lg-2 desktopView">
          <h3>News</h3>
          {loadingNews ? "Loading..." : showNews()}         
        </div>
        </div>
        <br/>
    </div>
  );
}
