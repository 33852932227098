import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./projects.css";
import axios from "axios";

export default function Projects(props) {
  const params = useParams();

  // Project Variables
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [mainProjects, setMainProjects] = useState([]);
  const [loadingProjectCategories, setLoadingProjectCategories] = useState(true);
  const [projectCategories, setProjectCategories] = useState([]);
  const [loadingImages, setLoadingImages] = useState([]);
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    getProjectCategories();
    getProjects();
    getMedia();
  }, []);

  async function getProjectCategories() {
    if (loadingProjects) {
      const returnInfo = await axios.get("https://mr.lerudi.com/wp-json/wp/v2/projectcategories?per_page=100");
      await setProjectCategories(returnInfo.data);
      setLoadingProjectCategories(false);
    }
  }

  async function getProjects() {
    if (loadingProjects) {
      const returnInfo = await axios.get("https://mr.lerudi.com/wp-json/wp/v2/project?per_page=100");
      await setMainProjects(returnInfo.data);
      setLoadingProjects(false);
    }
  }

  async function getMedia() {
    if (loadingImages) {
      const returnInfo = await axios.get(
        "https://mr.lerudi.com/wp-json/wp/v2/media?per_page=100"
      );
      await setImages(returnInfo.data);
      setLoadingImages(false);
    }    
  }

  function switchMainProjectImage(newImage) {
    document.getElementById("mainProjectShowImage").src=newImage;
  }

  function showProject(ProjectId) {
    // Loop through the Projects in the "ProjectParameter" parameter
    const curProject = mainProjects.filter(x=>Number(x.id)===Number(ProjectId))[0];
    console.log(images.filter(x=>Number(x.id)===Number(curProject.acf.picture_2)))
    const mainProjectPicture = loadingImages ? "" : images.filter(x=>Number(x.id)===Number(curProject.acf.picture))[0].source_url;
    const projectPicture2 = loadingImages ? "" : images.filter(x=>Number(x.id)===Number(curProject.acf.picture_2))[0] === undefined ? "" : images.filter(x=>Number(x.id)===Number(curProject.acf.picture_2))[0].source_url;
    const projectPicture3 = loadingImages ? "" : images.filter(x=>Number(x.id)===Number(curProject.acf.picture_3))[0] === undefined ? "" : images.filter(x=>Number(x.id)===Number(curProject.acf.picture_3))[0].source_url;
    const projectPicture4 = loadingImages ? "" : images.filter(x=>Number(x.id)===Number(curProject.acf.picture_4))[0] === undefined ? "" : images.filter(x=>Number(x.id)===Number(curProject.acf.picture_4))[0].source_url;

    if (curProject) {
      const a = curProject.acf.description.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2');
      return <div className="col-12">
                <div className="row">
                  <div className="col-sm-12 col-lg-9"><img className="projectshow-image" id="mainProjectShowImage" alt="" src={mainProjectPicture} /></div>
                  <div className="col-sm-12 col-lg-3">
                    <h3 className="">{curProject.title.rendered}</h3>
                    {a}
                    <br/><br/>
                    <div className="row">
                      {mainProjectPicture === undefined ? "" : <img 
                      className="col-sm-6 col-lg-6 projectshow-thumb" 
                      alt="Show main picture" 
                      title="Show main picture" 
                      onClick={()=>switchMainProjectImage(mainProjectPicture)}
                      src={mainProjectPicture} />}
                      {projectPicture2 === "" ? "" : <img 
                      className="col-sm-6 col-lg-6 projectshow-thumb" 
                      alt="Show picture 2" 
                      title="Show picture 2" 
                      onClick={()=>switchMainProjectImage(projectPicture2)} 
                      src={projectPicture2} />}
                      {projectPicture3 === "" ? "" : <img 
                      className="col-sm-6 col-lg-6 projectshow-thumb"                                        
                      alt="Show picture 3" 
                      title="Show picture 3" 
                      onClick={()=>switchMainProjectImage(projectPicture3)} 
                      src={projectPicture3} />}
                      {projectPicture4 === "" ? "" : <img 
                      className="col-sm-6 col-lg-6 projectshow-thumb"
                      alt="Show picture 4" 
                      title="Show picture 4" 
                      onClick={()=>switchMainProjectImage(projectPicture4)} 
                      src={projectPicture4} />}
                    </div>            
                  </div>
                </div>
            </div>
      }    
  }

  function showMainProjects() {
    // Loop through the Projects in the "ProjectParameter" parameter
    if (params.projectCategoryId) {
      return mainProjects.filter(x=>Number(x.acf.category[0])===Number(params.projectCategoryId))
      .map((Project) => (
        <div className="col-sm col-lg-3" key={"project_" + Project.id}>     
          <a href={"/projects/project/" + Project.id}>         
          <div className="projectbox-div" title={props.description}
              style={{ backgroundImage: `url('${loadingImages ? "" : images.filter(x=>Number(x.id)===Number(Project.acf.picture))[0].source_url}')` }}>                
              <div className="projectbox-text">{Project.title.rendered}</div>
          </div>  
          </a>
        </div> 
      ));
    } else {
      return mainProjects
      .map((Project) => (
        <div className="col-sm col-lg-3" key={"project_" + Project.id}>     
          <a href={"/projects/project/" + Project.id}>         
          <div className="projectbox-div" title={props.description}
              style={{ backgroundImage: `url('${loadingImages ? "" : images.filter(x=>Number(x.id)===Number(Project.acf.picture))[0].source_url}')` }}>                
              <div className="projectbox-text">{Project.title.rendered}</div>
          </div>  
          </a>
        </div> 
      ));
    }    
  }

  function showProjectCategories() {
    // Loop through the project categories
    return projectCategories
          .map((projectCategory) => (<div key={"projectCategory_" + projectCategory.id} className="projectCategory-link-div">
            <a key={"projectCategoryLink_" + projectCategory.id}  href={"/projects/category/" + projectCategory.id} className={Number(params.projectCategoryId)===Number(projectCategory.id) ? "projectCategory-link-base projectCategory-link-active" : "projectCategory-link-base projectCategory-link"}>         
              <span dangerouslySetInnerHTML={{__html: projectCategory.title.rendered}}></span>
            </a> |&nbsp;</div>
          ));
  }

  return (
    <div> 
      With deep skills and experience we are proudly part of some of the most cutting-edge projects in recent years<br/><br/>
      {params.projectId === undefined ? <div className="row">
            <div className="col-12 projectCategory-div">
              <span>Categories:&nbsp;</span> <a href="/projects" className={params.projectCategoryId === undefined ? "projectCategory-link-base projectCategory-link-active" : "projectCategory-link-base projectCategory-link"}>All</a> |&nbsp; {loadingProjectCategories ? "Loading..." : showProjectCategories()}  
            </div> 
        </div> : ""}        
        <br/>
        <div className="row">
              {params.projectId !== undefined && !loadingProjects ? loadingProjects ? "Loading..." : showProject(params.projectId) : ""}
              {params.projectId === undefined ? loadingProjects ? "Loading..." : showMainProjects() : ""}              
        </div>
        <br/>
    </div>
  );
}
